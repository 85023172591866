import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MediaService } from '../../../../services/media.service';
import { SnackbarService } from '../../../../shared/snackbar/snackbar.service';
import { MSXReportService } from '../../../../services/msx_report.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarningModalComponent } from '../../../../../app/modals/warning-modal/warning-modal.component';
import { Subscription } from 'rxjs';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';
// eslint-disable-next-line @angular-eslint/use-component-selector


declare var FB: any;
declare var gapi: any;
@Component({
  selector: 'app-add-link',
  templateUrl: './add-link.component.html',
  styleUrls: ['./add-link.component.scss']
})
export class AddLinkComponent implements OnInit, OnDestroy {
  authSubscription!: Subscription;
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
  options = {
    types: [],
    componentRestrictions: { country: 'UA' }
  };

  message: any;
  authResponse: any;
  userDetails: any;
  longLivedToken: any;
  userPages: any;
  reviews: any;
  selectedPage: any;
  mediaForm: UntypedFormGroup;
  submitted = false;
  locations = [];
  practices = [];
  providers = [];
  page_id = "";
  facebookPageSettings: any = {};
  selectedOption = "";
  selected_fbPage = [];
  selectedFBPages = [];
  locationid = "";
  practiceid = "";
  socialLinks = [];
  place: any;
  showPlaceControl = false;
  showSearchLocation = false
  googleUser: any;
  constructor(private http: HttpClient, private fb: UntypedFormBuilder,
    private media: MediaService, private snackbar: SnackbarService,
    private msxReportService: MSXReportService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public router: Router,
    private authService: SocialAuthService
  ) { }

  private DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
  private SCOPES = 'https://www.googleapis.com/auth/drive';

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.facebookPageSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      allowSearchFilter: false,
      enableCheckAll: false,
      clearSearchFilter: true
    };

    this.GetEntity();
    this.mediaForm = this.fb.group({
      practice: ["", Validators.required],
      location: ["", Validators.required],
      page_id: [""],
      provider: [""],
      userid: [""],
      facebook: [false]
    });

    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: '911202590732922',
        status: true,
        cookie: true,
        xfbml: true,
        version: 'v2.4'
      });
      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js: HTMLScriptElement;
      js = document.createElement("script");
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      document.body.appendChild(js);
    }(document, 'script', 'facebook-jssdk'));

    this.authSubscription = this.authService.authState.subscribe((user) => {
      this.showSearchLocation = true;
      this.googleUser = user;
    });


  }

  get form() {
    return this.mediaForm.controls;
  }

  public GetEntity() {
    let selectedGroup = localStorage.getItem("selectedGroup") || ""
    this.msxReportService.GetEntity(selectedGroup, "").subscribe(
      (data) => {
        this.locations = data[0].attributes.data.graphLocations;
        this.practices = data[0].attributes.data.practices;
        this.providers = data[0].attributes.data.providers;

      },
      (err) => {
        this.snackbar.show("error occurred please try again later.", "danger");
      }
    );
  }
  ///Facebook 
  submitLogin() {

    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: '911202590732922',
        status: true,
        cookie: true,
        xfbml: true,
        version: 'v2.4'
      });
      FB.AppEvents.logPageView();
    };


    FB.login((response: { authResponse: any; }) => {
      //console.log('submitLogin', response);
      if (response.authResponse) {
        this.authResponse = response.authResponse;
        this.getLongLivedToken();
      }
      else {
        console.log('User login failed');
      }
    });

  }

  getLongLivedToken() {
    this.http
      .get<any>('https://graph.facebook.com/v14.0/oauth/access_token?grant_type=fb_exchange_token&client_id=911202590732922&client_secret=4f9b330234e16048e64ec3ed679e564e&fb_exchange_token=' + this.authResponse.accessToken, {
      })
      .subscribe((tokenData: any) => {
        this.longLivedToken = tokenData;
        this.getPages();
      });
  }

  getPages() {
    this.http
      .get<any>('https://graph.facebook.com/v14.0/' + this.authResponse.userID + '/accounts?access_token=' + this.longLivedToken.access_token, {
      })
      .subscribe((pageData: any) => {
        this.userPages = pageData.data;
      });
  }


  ///Google
  async submitGoogleLogin() {

  }


  /////


  /////


  onSubmit() {
    this.submitted = true;
    if (this.mediaForm.valid) {
      let pages = [];
      this.userPages.forEach(element => {
        this.selectedFBPages.forEach(page => {
          if (element.id === page.id) {
            pages.push(element);
          }
        });
      });
      this.selectedFBPages = pages;
      this.media.save(this.authResponse, this.longLivedToken.access_token, this.mediaForm.value, this.selectedFBPages).subscribe(
        (data) => {
          this.snackbar.show(data[0].attributes.data, "success");
          this.router.navigate(["/media/activity/"]);
        },
        (error) => {

          this.snackbar.show(error[0] !== undefined ? error[0].detail : error.title, "danger");
        }
      );

    } else {

    }
  }

  onItemSelect(item: any) {
    this.selectedFBPages.push(item)
  }

  onItemDeSelect(item: any) {
    this.selectedFBPages.forEach((element, index) => {
      if (element.name === item.name) {
        this.selectedFBPages.splice(index, 1)
      }
    });
  }

  public addNew() {
    this.router.navigate(["/media/edit-link/"]);
  }

  public onCancel() {
    this.router.navigate(["/media/activity/"]);
  }

  googleSignin(googleWrapper: any) {
    googleWrapper.click();
  }
  public handleAddressChange(place: any) {
    this.place = place;
    this.showPlaceControl = true;

  }

  savePlace() {
    if (this.mediaForm.valid) {
      let pages = [];
      this.media.savePlace(this.mediaForm.value, this.place.place_id, this.place.name, this.googleUser).subscribe(
        (data) => {
          this.snackbar.show(data[0].attributes.data, "success");
          this.router.navigate(["/media/activity/"]);
        },
        (error) => {

          this.snackbar.show(error[0] !== undefined ? error[0].detail : error.title, "danger");
        }
      );

    } else {
      this.snackbar.show("Please provide practice,location and provider", "danger");
    }

  }

}
