<div class="container-fluid">
    <div class="tab">
        <a class="tablinks active" [routerLink]="['/media/activity']">
            <img src="/assets/msx/social-icon-white.png">Social Media Activity
        </a>
        <a class="tablinks" [routerLink]="['/media/reviews']">
            <i class="fa-solid fa-reply"></i> Respond to Reviews
        </a>
    </div>

    <form [formGroup]="mediaForm">
        <h3>Add Social Links</h3>
        <div class="row">
            <div class="col-1 pr-0 text-right">
                <label style="margin-top: 10px;">Practice</label>
            </div>
            <div class="col-5">
                <div class="form-group">
                    <select formControlName="practice" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && form.practice.errors }">
                        <option value="">Please select</option>
                        <option *ngFor="let p of practices" [ngValue]="p">{{ p.name }}</option>
                    </select>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-1 pr-0 text-right">
                <label style="margin-top: 10px;">Location</label>
            </div>
            <div class="col-5">
                <div class="form-group">
                    <select formControlName="location" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && form.location.errors }">
                        <option value="">Please select</option>
                        <option *ngFor="let l of locations" [ngValue]="l">{{ l.name }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-1 pr-0 text-right">
                <label style="margin-top: 10px;">Providers</label>
            </div>
            <div class="col-5">
                <div class="form-group">
                    <select formControlName="provider" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && form.location.errors }">
                        <option value="">Please select</option>
                        <option *ngFor="let p of providers" [ngValue]="p">{{ p.name }}</option>
                    </select>
                </div>
            </div>
        </div>






        <div class="row mt-2">
            <!-- <app-google-signin (loginWithGoogle)="googleSignin($event)"></app-google-signin> -->
            <!-- <input #placesRef="ngx-places" ngx-gp-autocomplete (onAddressChange)="handleAddressChange($event)" /> -->
            <div class="col-1 pr-0 text-right">
                <label>Google</label>
            </div>
            <div class="col-3">
                <input formControlName="facebook" type="checkbox" id="facebook" value="facebook">
                &nbsp; <label for="google"> Show in Survey</label>
            </div>
            <div class="col-2 text-right">
                <!-- <button [disabled]="authResponse" (click)="submitGoogleLogin();"
                    class="btn btn-primary">{{!authResponse?"Connect":"Connected"}} </button>
                <button *ngIf="!this.userPages && authResponse" class="btn btn-primary ml-2 mt-3" type="button"
                    disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </button> -->
                <app-google-signin (loginWithGoogle)="googleSignin($event)"> </app-google-signin>

            </div>
        </div>


        <div *ngIf="showSearchLocation" class="row mt-2">
            <!-- <app-google-signin (loginWithGoogle)="googleSignin($event)"></app-google-signin> -->
            <!-- <input #placesRef="ngx-places" ngx-gp-autocomplete (onAddressChange)="handleAddressChange($event)" /> -->
            <div class="col-1 pr-0 text-right">
                <label>Location:</label>
            </div>

            <div class="col-5 text-right">
                <input class="form-control" #placesRef="ngx-places" ngx-gp-autocomplete
                    (onAddressChange)="handleAddressChange($event)" />
            </div>
        </div>

        <div class="row mt-2 mb-2">
            <!-- <app-google-signin (loginWithGoogle)="googleSignin($event)"></app-google-signin> -->
            <!-- <input #placesRef="ngx-places" ngx-gp-autocomplete (onAddressChange)="handleAddressChange($event)" /> -->
            <div class="col-1 pr-0 text-right">

            </div>
            <div class="col-3">

            </div>
            <div class="col-2 text-right">
                <button *ngIf="showPlaceControl" (click)="savePlace()" class="btn btn-primary">Save </button>
            </div>
        </div>

        <div *ngIf="socialLinks.length" class="row mt-2">
            <div class="col-1 pr-0 text-right">
                <label>Facebook Page(s)</label>
            </div>
            <div class="col-5">
                <ng-container *ngFor="let s of socialLinks">
                    <ng-container *ngFor="let p of s['facebook']?.pages">
                        <span class="badge bg-secondary"> {{p.page_name}}</span> &nbsp;
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-1 pr-0 text-right">
                <label>Facebook</label>
            </div>
            <div class="col-3">
                <input formControlName="facebook" type="checkbox" id="facebook" value="facebook">
                &nbsp; <label for="google"> Show in Survey</label>
            </div>
            <div class="col-2 text-right">
                <button [disabled]="authResponse" (click)="submitLogin();"
                    class="btn btn-primary">{{!authResponse?"Connect":"Connected"}} </button>
                <button *ngIf="!this.userPages && authResponse" class="btn btn-primary ml-2 mt-3" type="button"
                    disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </button>
            </div>

        </div>

        <div *ngIf="socialLinks.length" class="row mt-2">
            <div class="col-1 pr-0 text-right">
                <label>Yelp Page(s)</label>
            </div>

            <div class="col-5">
                <ng-container *ngFor="let s of socialLinks">
                    <ng-container *ngFor="let p of s['yelp']?.pages">
                        <span class="badge bg-secondary"> {{p.page_name}}</span> &nbsp;
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <div *ngIf="userPages" class="row mt-2">
            <div class="col-1 pr-0 text-right">
                <label style="margin-top: 10px;">Pages:</label>
            </div>
            <div class="col-5">
                <div class="form-group">
                    <ng-multiselect-dropdown formControlName="page_id" [placeholder]="'Please Select'"
                        style="width:100%" [settings]="facebookPageSettings" [data]="userPages"
                        [(ngModel)]="selected_fbPage" (onSelect)="onItemSelect($event)"
                        (onDeSelect)="onItemDeSelect($event)"
                        [ngClass]="{ 'is-invalid': submitted && form.page_id.errors }">
                    </ng-multiselect-dropdown>
                    <span *ngIf="submitted && form.page_id.errors"
                        [ngClass]="{ 'is-invalid': submitted && form.page_id.errors }" style="color:red;">
                        Please select a page.
                    </span>
                    <!-- <select [(ngModel)]="page_id" formControlName="page_id" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && form.page_id.errors }">
                        <option value="">Please select</option>
                        <option *ngFor="let p of userPages" [ngValue]="p">{{ p.name }}</option>
                    </select> -->
                </div>
            </div>
        </div>
        <div *ngIf="this.userPages && authResponse" class="row mt-2">
            <button type="button" (click)="onCancel();" class="btn btn-secondary ml-2 mr-2">Cancel</button>
            <button [disabled]="!authResponse" (click)="onSubmit();" class="btn btn-primary">Save</button>

        </div>
    </form>

</div>