import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";
import { AvcHttp } from "../providers/avc-http";
import { Helper } from "../providers/helper";

@Injectable()
export class AppsService {
    private handler = `/links`;
    private url = `${environment.uri}${this.handler}`;
    constructor(private avcHttp: AvcHttp, private helper: Helper) { }

    public getApps(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
    public getProviderApps(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", environment.uri + "/apps/provider/list", {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public updateApp(app, appid): Observable<any> {
        let data = JSON.stringify({
            action: app.value.appAction,
            title: app.value.appTitle,
            description: app.value.appDescription,
            imageurl: app.value.appImageUrl,
            actionurlios: encodeURI(app.value.appActionUrliOS),
            actionurlandroid: encodeURI(app.value.appActionUrlAndroid),
            buttontext: app.value.appButtonText,
            buttonurl: app.value.appButtonUrl,
            linktype: app.value.linktype,
            datapointstype: "aaa",
            sortorder: "1",
            actiontext: app.value.appActionButtonText,
            infotext: app.value.appInfoButtonText,
            infourl: app.value.appInfoButtonUrl,
        });
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", this.url + "/" + appid, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public deleteApp(appId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", this.url + "/" + appId).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public assignUser(userId, appId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("post", this.url + "/user/" + userId + "/" + appId).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public assignLinksToUser(appIds, userId): Observable<any> {
        let data = JSON.stringify({
            appids: appIds,
        });

        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("put", this.url + "/user/" + userId, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public unassignUser(userId, appId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("delete", this.url + "/user/" + userId + "/" + appId).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public getAssignedUsers(appId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/" + appId).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public getUserApps(userId): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/user/" + userId + "?membershipid=" +
                localStorage.getItem("selectedGroup") || "").subscribe(
                    (body) => {
                        body = this.helper.ParseToJson(body);
                        if (body.data !== undefined) {
                            observer.next(body.data);
                            observer.complete();
                        } else {
                            observer.error(body.errors);
                            observer.complete();
                        }
                    },
                    (err) => {
                        observer.error(err);
                        observer.complete();
                    }
                );
        });
    }

    public createApp(app): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            let data = JSON.stringify({
                action: app.value.appAction,
                title: app.value.appTitle,
                description: app.value.appDescription,
                imageurl: app.value.appImageUrl,
                actiontext: app.value.appActionButtonText,
                infotext: app.value.appInfoButtonText,
                infourl: app.value.appInfoButtonUrl,
                datapointstype: "",
                sortorder: "1",
                actionurlios: encodeURI(app.value.appActionUrliOS),
                actionurlandroid: encodeURI(app.value.appActionUrlAndroid),
                linktype: app.value.linktype,
            });

            this.avcHttp.sendRequest("post", this.url, data).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    public getCareteamApps(careteamid, search = ""): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            this.avcHttp.sendRequest("get", this.url + "/userlist/" + careteamid + "?search=" + search, {}, {}).subscribe(
                (body) => {
                    body = this.helper.ParseToJson(body);
                    if (body.data !== undefined) {
                        observer.next(body.data);
                        observer.complete();
                    } else {
                        observer.error(body.errors);
                        observer.complete();
                    }
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }
}
